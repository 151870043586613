.search {
    &__boxes {
        border-color: #D9C4C9;
        background-color: #FBF4F6;
    }
    dl {
        dd {
            input,
            select {
                border-color: #ccc;
            }
        }
    }
    button {
        color: #fff;
    }
}




