.tide {
    &__simple {
        &__box {
            table {
                border-color: #ccc;
            }
            th,
            td {
                border-top-color: #ccc;
                border-bottom-color: #ccc;
                border-left-color: #ccc;
                border-right-color: #ccc;
                color: #333;
            }
        }
    }
    &__chart-box {
        &__weather { // dl
            background-color: #fcfcfc;
            border-color: #ddd;
        }
    }
}

.sun--simple {
    background-color: #fffcf3;
}
.weather--simple {
    background-color: #f5fff3;
}
.max--simple {
    background-color: #ffeded;
}
.min--simple {
    background-color: #edf5ff;
}



