.header {
    background-color: #fff;
    &__menu {
        i {
            color: #8C7C80;
        }
    }
    &__logo__light {
        display: block;
    }
    &__logo__dark {
        display: none;
    }
    &__color-theme {
        &__icons {
            border-color: #ddd;
            i {
                &[data-icon="sun"] {
                    color: #e8ad51;
                }
                &[data-icon="moon"] {
                    color: #ccc;
                }
                &:first-child {
                    &::after {
                        background-color: #ddd;
                    }
                }
            }
        }
    }
}