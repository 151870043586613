.search {
    &__boxes {
        border-color: #69575A;
        background-color: #2C2628;
    }
    dl {
        dd {
            input,
            select {
                color-scheme: dark;
                border-color: #999;
            }
        }
    }
    button {
        //color: #;
    }
}
