.btn {
    &-my-primary {
        background-color: #0d6efd;
        color: #fff;
    }
    &-my-success {
        background-color: #198754;
        color: #fff;
    }
    &:focus {
        outline: none;
        outline-offset: 0;
    }
}