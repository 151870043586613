.header {
    background-color: #0C1C46;
    &__logo__light {
        display: none;
    }
    &__logo__dark {
        display: block;
    }
    &__color-theme {
        &__icons {
            border-color: #666;
            i {
                &[data-icon="sun"] {
                    color: #666;
                }
                &[data-icon="moon"] {
                    color: #ccb94a;
                }
                &:first-child {
                    &::after {
                        background-color: #666;
                    }
                }
            }
        }
    }
}