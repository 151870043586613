// box
.gm-style-iw {
    border: 1px solid #69575A;
    background-color: #2C2628;
}

// 閉じる
.gm-style-iw-chr {
    button {
        span {
            background-color: #fff;
        }
    }
}